import React from "react";

import {
  SectionWrapper,
  Box,
  Text,
  Loader,
  Button,
  TextInput
} from "src/components";

const Login = ({
  adminPassword,
  setAdminPassword,
  processing,
  login,
  error
}) => (
  <SectionWrapper bg="bg.reverse">
    {processing && (
      <Box py={10}>
        <Loader invert={true} />
      </Box>
    )}
    {!processing && (
      <Box
        py={[5, 6, 7]}
        maxWidth={420}
        mx="auto"
        as="form"
        onSubmit={event => {
          event.preventDefault();
          login();
        }}
      >
        <Text
          children={
            "Before you can send or view notification statistics, please enter the admin password."
          }
          color="text.reverse"
        />
        <Box py={4}>
          <TextInput
            label={`Password`}
            placeholder={`Admin password`}
            type="password"
            value={adminPassword}
            onChange={event => {
              setAdminPassword(event.target.value);
            }}
          />
        </Box>
        <Box>
          <Button children={"Authorize"} variant="primary" />
        </Box>
        {error && (
          <Text
            children={
              "You may have entered the incorrect password. Try again, and if this error persists, reach out to the development team to assist."
            }
            color="text.reverse"
            mt={2}
          />
        )}
      </Box>
    )}
  </SectionWrapper>
);

export default Login;
