import React, { useState, useRef, useEffect } from "react";
import { graphql } from "gatsby";
import moment from "moment-timezone";

import { useSchedule } from "src/contexts/schedule";

import {
  SEO,
  PageHeaderV2,
  FlexGrid,
  FlexCol,
  SectionWrapper,
  H3,
  Text,
  Textarea,
  Button,
  Box,
  Modal,
  H2,
  H6,
  FlexRow,
  Loader
} from "src/components";

import Login from "./_components/_login";

const ConfirmModal = ({
  close,
  send,
  analytics,
  sending,
  sendingError,
  sendingSuccess
}) => (
  <Box p={[6, 8]}>
    {!sending && !sendingSuccess && (
      <>
        <H2 children={"Confirm message"} mb={4} />
        <Text
          children={
            "Based on having " +
            analytics.marketingAllowed +
            " users who allow marketing notifications, it will cost you $" +
            (0.0075 * analytics.marketingAllowed).toFixed(2) +
            " to send this message. Please make sure you are okay with this cost before pressing send."
          }
        />
        <FlexRow mt={4}>
          <Button children={"Confirm"} variant="primary" onClick={send} />
          <Box ml={2}>
            <Button children={"Cancel"} onClick={close} />
          </Box>
        </FlexRow>
        {sendingError && (
          <Text
            children={
              "There was a problem sending out the message. Please refresh the page and try again. If that doesn't work, please contact the development team."
            }
            mt={2}
          />
        )}
      </>
    )}
    {sending && <Loader />}
    {sendingSuccess && (
      <>
        <H2 children={"Message sent!"} mb={2} />
        <Box>
          <Button children={"Back"} onClick={close} />
        </Box>
      </>
    )}
  </Box>
);

const AnalyticsNode = ({ value, label }) => (
  <FlexCol width={[1 / 2, 1 / 4]}>
    <H3 children={value} textAlign="center" mb={2} />
    <Text children={label} color="text.alt" textAlign="center" />
  </FlexCol>
);

function AdminInner({ festivals, setInvert, setInvertLogo }) {
  const { activeFestival } = useSchedule();
  const [newMessage, setNewMessage] = useState("");
  const [adminPassword, setAdminPassword] = useState("");
  const [error, setError] = useState(false);
  const [processing, setProcessing] = useState(false);
  const [sending, setSending] = useState(false);
  const [sendingError, setSendingError] = useState(false);
  const [sendingSuccess, setSendingSuccess] = useState(false);
  const [loggedIn, setLoggedIn] = useState(false);
  const [analytics, setAnalytics] = useState({});

  const login = () => {
    setError(false);
    setProcessing(true);
    fetch("/.netlify/functions/adminGetSchedules", {
      method: "POST",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json"
      },
      body: JSON.stringify({
        password: adminPassword,
        activeFestival: activeFestival
      })
    })
      .then(results => {
        return results.json();
      })
      .then(({ success, analytics }) => {
        if (success) {
          setProcessing(false);
          setLoggedIn(true);
          setAnalytics(analytics);
        } else {
          setError(true);
          setProcessing(false);
        }
      });
  };

  const send = () => {
    setSendingError(false);
    setSending(true);
    fetch("/.netlify/functions/adminSendNotification", {
      method: "POST",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json"
      },
      body: JSON.stringify({
        password: adminPassword,
        activeFestival: activeFestival,
        message: newMessage
      })
    })
      .then(results => {
        return results.json();
      })
      .then(({ success }) => {
        if (success) {
          setSending(false);
          setSendingSuccess(true);
          setNewMessage("");
        } else {
          setSendingError(true);
          setSending(false);
        }
      });
  };

  const schedule = () => {
    setError(false);
    setProcessing(true);
    setLoggedIn(false);
    fetch("/.netlify/functions/adminSendNotification", {
      method: "POST",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json"
      },
      body: JSON.stringify({
        password: adminPassword,
        activeFestival: activeFestival,
        message: newMessage
      })
    })
      .then(results => {
        return results.json();
      })
      .then(({ success }) => {
        if (success) {
          setProcessing(true);
          setLoggedIn(true);
        } else {
          setProcessing(true);
          setLoggedIn(true);
        }
      });
  };

  return (
    <>
      <SEO title={"Admin"}>
        <meta name="robots" content="noindex" />
      </SEO>
      <PageHeaderV2
        heading={"Folk Fest Notification Admin Panel"}
        color="brand.primary"
        invert={true}
        setInvertLogo={setInvertLogo}
        setInvert={setInvert}
      />
      {!loggedIn && (
        <Login
          processing={processing}
          error={error}
          adminPassword={adminPassword}
          setAdminPassword={setAdminPassword}
          login={login}
        />
      )}
      {loggedIn && (
        <SectionWrapper py={[5, 6, 7]}>
          {analytics.count && (
            <FlexGrid gutterX={[3]} gutterY={[4]}>
              <AnalyticsNode
                value={analytics.count}
                label="Schedules created"
              />
              <AnalyticsNode
                value={analytics.avgScheduleLength.toFixed(1)}
                label="Average number of schedule items added"
              />
              <AnalyticsNode
                value={
                  (
                    (analytics.notificationsAllowed / analytics.count) *
                    100
                  ).toFixed(1) + "%"
                }
                label="Show notifications rate"
              />
              <AnalyticsNode
                value={
                  (
                    (analytics.marketingAllowed / analytics.count) *
                    100
                  ).toFixed(1) + "%"
                }
                label="Marketing permitted rate"
              />
            </FlexGrid>
          )}
          <Box mt={6} maxWidth={600} mx="auto">
            <Textarea
              label="Message"
              placeholder="Message to send out. Must be less than 120 characters."
              rows={3}
              value={newMessage}
              onChange={event => {
                if (event.target.value.length < 120) {
                  setNewMessage(event.target.value);
                }
              }}
            />
            <Box
              mt={2}
              style={{
                pointerEvents: newMessage.length < 20 ? "none" : "all"
              }}
            >
              <Modal
                trigger={
                  <Button
                    children={"Send message"}
                    onClick={() => {
                      setSendingSuccess(false);
                    }}
                    disabled={newMessage.length < 20}
                  />
                }
                children={
                  <ConfirmModal
                    send={send}
                    analytics={analytics}
                    sending={sending}
                    sendingError={sendingError}
                    sendingSuccess={sendingSuccess}
                  />
                }
              />
            </Box>
            {/* <Box mt={8}>
              <H2 children={`Scheduled Posts`} mb={4} />
            </Box> */}
            {analytics.popularity && (
              <Box mt={8}>
                <H2 children={`Top 50`} mb={4} />
                {analytics.popularity.map(concert => (
                  <FlexRow
                    borderBottom={"1px solid black"}
                    mb={2}
                    pb={2}
                    justifyContent="space-between"
                  >
                    <Box>
                      <Text
                        children={
                          concert.primary.performer
                            ? concert.primary.performer.uid
                            : concert.primary.title
                        }
                        mb={1}
                      />
                      <H6
                        children={moment(concert.primary.start_time)
                          .tz("America/Edmonton")
                          .format("MMM DD, hh:mm a")}
                      />
                    </Box>
                    <Text children={concert.popularity} color="text.alt" />
                  </FlexRow>
                ))}
              </Box>
            )}
          </Box>
        </SectionWrapper>
      )}
    </>
  );
}

const AdminPage = ({ setInvert, setInvertLogo }) => {
  let festivals = [];

  return (
    <AdminInner
      festivals={festivals}
      setInvert={setInvert}
      setInvertLogo={setInvertLogo}
    />
  );
};

export default AdminPage;

export const query = graphql`
  {
    allPrismicFestival {
      edges {
        node {
          uid
        }
      }
    }
    allPrismicArtist {
      edges {
        node {
          uid
        }
      }
    }
  }
`;
